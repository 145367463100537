import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Code Xafly Auto Detailing
			</title>
			<meta name={"description"} content={"Відкрийте для себе блиск - Code Xafly, де блиск вашого автомобіля - наша пристрасть\n"} />
			<meta property={"og:title"} content={"Code Xafly Auto Detailing"} />
			<meta property={"og:description"} content={"Відкрийте для себе блиск - Code Xafly, де блиск вашого автомобіля - наша пристрасть\n"} />
			<meta property={"og:image"} content={"https://ua.codexafly.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 200px 0" quarkly-title="Contacts-3">
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				text-align="left"
				padding="0px 0 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
			>
				Зв'яжіться з нами
			</Text>
			<Text
				margin="16px 40% 50px 0px"
				font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
				lg-margin="0px 0 50px 0px"
				text-align="left"
				sm-margin="0px 0 35px 0px"
				color="#60666d"
				lg-max-width="720px"
			>
				Щоб дізнатися більше про наші послуги або запланувати подорож вашого автомобіля до блиску, зв'яжіться з нашою командою:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				lg-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
						Адреса
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#60666d">
						Южная дорога 3/9, с. Крижанівка,
						<br />
						{" "}Одеса, 65069
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
						Телефон
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#60666d">
						093 158 77 44
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
						Електронна пошта
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#60666d">
						contact-us@ua.codexafly.com
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});